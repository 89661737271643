<template>
	<v-sheet>
		<h2 v-if="question">{{ question.title }}</h2>
		<loading v-if="loading"></loading>
		<v-col>
			<p>Updating {{ responses.length - complete.length }} records</p>
			<p>Completed {{ complete.length }} records</p>
			<v-btn @click="startAt = complete.length + 1">Get Next</v-btn>
			<v-btn @click="submit">Submit</v-btn>
		</v-col>
		<v-col></v-col>
	</v-sheet>
</template>

<script>
import Loading from "@c/ui/Loading";
export default {
	name: "FindOldResponsesToProfileFields",
	props: {
		id: { type: String },
	},
	data: () => {
		return {
			loading: true,
			startAt: 0,
			max: 400,
            complete: []
		};
	},
	computed: {
		question() {
			return this.$store.state.questions.data[this.id];
		},
		responses() {
			let id = this.id;
			let surveyResponses = Object.values(
				this.$store.state.surveyResponses.data
			);
			return surveyResponses.filter((a) => a.data[id]);
		},
        updateRecords(){
            return this.responses.filter( r => !r.done );
        }
	},
	methods: {
		newProfileField(a) {
			let doc = {
				profile_field: this.question.profile_field,
				question: this.id,
				status: "published",
				user: a.participant,
				value: a.data[this.id],
			};
			a.done = true;
			this.$store.dispatch("profileFieldValues/new", doc);
		},
		submit() {
			const self = this;
			self.loading = true;
            let records = self.responses.filter( r => !self.complete.includes(r.id))
			records.forEach((r) => {
                self.complete.push( r.id );
                self.newProfileField(r);
				self.loading = false;
			});
		},
		get() {
			const self = this;

			self.$store
				.dispatch("surveyResponses/fetchAndAdd", {
					clauses: {
						where: [[`data.${this.id}`, "!=", null]],
						limit: this.max,
						startAt: this.startAt,
						orderBy: "created_at",
					},
				})
				.then(() => {
					self.loading = false;
				});
		},
	},
	components: {
		Loading,
	},
	watch: {
		startAt() {
			this.get();
		},
	},

	created() {
		const self = this;
		self.$store.dispatch("questions/fetchById", this.id);
		self.get();
	},
};
</script>
